.btn {
  font-size: 16px;
  font-family: $mainSansSerifBold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  color: #ffffff;
  background-color: $primaryColor;
  padding: 10px 20px;
  line-height: 25px;
  cursor: pointer;
  text-decoration: none;
  transition: all .2s ease;
  border: 1px solid transparent;

  svg {
    margin-right: 10px;
  }

  &:hover {
    color: #fff;
    background-color: $secondaryColor;
    border-color: transparent;
  }

  &--border {
    background: transparent;
    border-color: rgba(250, 250, 250, .5);
    color: #fff;

  }

  &--dark {
    background-color: $darkBox;

    &:hover {
      background-color: $primaryColor;
    }
  }

  &--red {
    background-color: $redColor;

    &:hover {
      background-color: $primaryColor;
    }
  }

  &:active {
    transform: scale(.95);
  }
}

.landing-pages {
  background: $dark;
  color: #fff;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;


  .container {
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
    padding: 0;
    @include media("<1200px") {
      padding: 0 15px;
    }

    &--big {
      max-width: 1330px;
    }
  }

  .footer {
    margin-top: auto;
    padding: 15px 0;

    .copyright {
      font-size: 11px;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      padding: 10px 0;

      @include media("<768px") {
        flex-direction: column;
      }
    }

    &__info {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }
  }

  .header {
    position: relative;
    z-index: 99999;


    .header-logo {
      width: 110px;
      height: 50px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .ant-select-arrow {
      color: #fff !important;
    }
    &__buttons {
      display: flex;
      align-items: center;
      margin-left: 30px;

      @include media("<1200px") {
        display: none;
      }

      > * {
        + * {
          margin-left: 10px;
        }
      }
    }

    &__wrap {
      display: flex;
      align-items: center;
      height: 80px;
    }

    .language-select {
      @include media("<1200px") {
        display: none;
      }
    }
  }

  .burger-menu, .menu-modal {
    display: none;
  }

  .burger-menu {
    margin-left: auto;
    padding: 0;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: $darkBox;
    border: none;
    display: none;


    img {
      transform: scaleX(-1);
    }

    &:hover {
      background-color: $primaryColor;
    }

    @include media("<1200px") {
      display: block;
    }
  }

  .pages-nav {
    padding: 0;
    list-style: none;
    display: flex;
    margin: 0;
    margin-left: auto;

    li {
      position: relative;
      z-index: 99;

      > * {
        font-size: 16px;
        color: #fff;
        font-family: $mainSansSerifBold;
      }

      a {
        &:hover,
        &.active {
          color: $primaryColor;
        }
      }

    }

    li + li {
      margin-left: 15px;
    }


    &--header {
      margin: 0 auto;

      @include media("<1200px") {
        display: none;
      }
    }

    &--footer {

      @include media("<768px") {
        flex-direction: column;
        margin: 0 auto;
        text-align: center;
        margin-top: 20px;

        li {
          + li {
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }

      a {
        font-size: 14px;

        @include media("<768px") {
          font-size: 12px;
        }
      }
    }
  }

  .language-dropdown {
    display: flex;
    align-items: center;
    font-family: $mainSansSerifBold;
    font-size: 16px;

    @include media("<1200px") {
      display: none;
    }

    > * {
      flex: none;
    }

    img {
      width: 36px;
      object-fit: contain;
      border-radius: 5px;
    }
  }

  .nav-cards-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    height: 600px;
    position: relative;
    z-index: 10;
    margin-top: -60px;

    @include media("<1200px") {
      display: block;
      height: auto;
      margin-top: 0;
    }
  }


  .nav-card {
    background: $darkBox;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.15);

    @include media("<1200px") {
      height: auto;
      min-height: 285px;
      margin-bottom: 20px;
    }


    &__title {
      font-family: $mainSansSerifBold;
      font-size: 32px;
      margin-bottom: 0;

      @include media("<768px") {
        font-size: 24px;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 21px;
      max-width: 250px;
      text-shadow: 0 0 20px #000;

      @include media("<768px") {
        font-size: 12px;
      }
    }

    &__content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 30px;
      padding-bottom: 40px;
      z-index: 999;

      @include media("<768px") {
        padding: 20px;
        padding-bottom: 40px;
      }
    }

    .btn {
      position: absolute;
      bottom: 30px;
      left: 30px;
      @include media("<768px") {
        left: 20px;
        bottom: 20px;
      }
    }

    .decor-item {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      animation: fly 3s infinite alternate both ease;

    }

    &:nth-child(1) {
      grid-area: 1 / 1 / 3 / 3;
      background: #EA6C21;

      @include media("<1200px") {
        min-height: 400px;
      }
      @include media("<768px") {
        min-height: 285px;
      }

      .card-image {
        position: absolute;
        bottom: -150px;
        right: -25px;
        width: 500px;
        animation: fly2 5s infinite alternate both linear;

        @include media("<1200px") {
          width: 300px;
        }

        @include media("<768px") {
          width: 200px;
          bottom: -40px;
          right: -10px;
        }

        img {
          max-width: 100%;
          object-fit: contain;
          transform: scale(-1, 1);
        }
      }

      .decor {
        @include media("<1200px") {
          transform: translateX(205px) scale(.7);
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }
      }

      .decor-item {
        &:nth-child(1) {
          margin-top: -225px;
          margin-left: 225px;
        }

        &:nth-child(2) {
          margin-top: -150px;
          margin-left: 190px;
          animation-delay: .5s;
          animation-duration: 5s;
        }

        &:nth-child(3) {
          margin-top: -120px;
          margin-left: 100px;
          animation-delay: .5s;
          animation-duration: 25s;

        }

        &:nth-child(4) {
          margin-top: -120px;
          margin-left: -150px;
          animation-duration: 5s;
          animation-name: fly2;
        }

        &:nth-child(5) {
          margin-top: -90px;
          margin-left: -140px;
          animation-delay: 1.5s;
          animation-duration: 10s;
        }

        &:nth-child(6) {
          margin-top: -50px;
          margin-left: -190px;
          animation-duration: 4s;
        }

        &:nth-child(7) {
          margin-top: 80px;
          margin-left: -220px;
          animation-duration: 9s;
        }

        &:nth-child(8) {
          margin-top: 250px;
          margin-left: -130px;
          animation-delay: .3s;
          animation-duration: 5s;
        }

        &:nth-child(9) {
          margin-top: 100px;
          margin-left: -130px;
          animation-duration: 5s;
        }
      }
    }

    &:nth-child(2) {
      grid-area: 1 / 3 / 2 / 5;
      background: #D4373E;


      .decor {
        @include media("<1200px") {
          transform: translateX(205px) scale(.7);
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }
      }

      .card-image {
        position: absolute;
        bottom: -130px;
        right: 0;
        width: 300px;
        animation: fly2 4s infinite alternate both linear;
        animation-delay: .1s;


        @include media("<768px") {
          width: 200px;
          bottom: -40px;
          right: -20px;
        }

        img {
          max-width: 100%;
          object-fit: contain;
        }
      }

      .decor {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(.5) rotate(-45deg);
      }

      .decor-item {
        &:nth-child(1) {
          margin-left: 100px;
          margin-top: -77px;
        }

        &:nth-child(2) {
          animation-delay: .2s;
          animation-duration: 8s;
          margin-left: 400px;
          margin-top: 217px;
        }

        &:nth-child(3) {
          animation-delay: .3s;
          animation-duration: 13s;
          margin-left: -70px;
          margin-top: 170px;

        }

        &:nth-child(4) {
          margin-left: 199px;
          margin-top: 35px;
          animation-duration: 7s;
        }

        &:nth-child(5) {
          animation-delay: 1s;
          animation-duration: 4s;
          margin-left: 132px;
          margin-top: 80px;
        }

        &:nth-child(6) {
          animation-duration: 4s;
          margin-left: 132px;
          margin-top: 480px;
        }

        &:nth-child(7) {
          animation-duration: 9s;
          margin-left: 280px;
          margin-top: 280px;
        }

        &:nth-child(8) {
          animation-delay: .3s;
          animation-duration: 5s;
          margin-left: 70px;
          margin-top: 25px;
        }

        &:nth-child(9) {
          margin-left: 240px;
          margin-top: 395px;
          animation-duration: 5s;
        }
      }
    }

    &:nth-child(3) {
      grid-area: 2 / 3 / 3 / 4;
    }

    &:nth-child(4) {
      grid-area: 2 / 4 / 3 / 5;
    }

    &--news,
    &--contacts {
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    @keyframes fly {
      0% {
        transform: translate(-50%, -50%);
      }
      100% {
        transform: translate(-50%, 0%);
      }
    }
    @keyframes fly2 {
      0% {
        transform: translate(0, 0px);
      }
      100% {
        transform: translate(0, 20px);
      }
    }
  }

  .main-banner {
    margin-top: -80px;
    overflow: hidden;

    .slick-dots {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 0;
      margin: 0;
      padding-bottom: 100px;
      z-index: 99;


      @include media("<1200px") {
        padding-bottom: 30px;
      }

      li {
        margin: 0;
        width: auto;
        height: auto;

        + li {
          margin-left: 10px;
        }

        button {
          padding: 0;
          font-size: 0;
          width: 8px;
          height: 8px;
          border: 1px solid rgba(250, 250, 250, .5);
          border-radius: 50%;
          transition: all .2s ease;
          cursor: pointer;
          background: none;

          &::before {
            display: none;
          }
        }

        &.slick-active {
          button {
            width: 50px;
            border-radius: 10px;
            background: rgba($redColor, .5);
            border-color: $redColor;
          }
        }
      }
    }
  }

  .banner {
    padding-top: 80px;
    height: 43vw;
    min-height: 620px;
    background: no-repeat center / cover;
    position: relative;

    @include media("<1200px") {
      min-height: 420px;
    }

    @include media("<768px") {
      min-height: 450px;
      background-size: 1024px;
      background-position: 70% center;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .35);
      }
    }
  }

  .banner-content {
    display: inline-block;
    position: relative;
    background: #f2a342;
    padding: 30px 50px;
    border-radius: 5px;
    margin-top: 100px;
    text-shadow: 0 0 30px #000;

    @include media("<1200px") {
      width: 100%;
      padding: 15px;
      margin-top: 30px;
      background: rgba(#f2a342, .65);
    }

    &__title {
      font-size: 48px;
      line-height: 1.2;
      font-family: $mainSansSerifBold;
      margin: 0;
      max-width: 550px;

      @include media("<1200px") {
        font-size: 32px;
        max-width: 350px;
      }

      @include media("<768px") {
        font-size: 26px;
        max-width: 300px;
      }
    }

    &__subtitle {
      font-size: 16px;
      line-height: 20px;
      max-width: 500px;
      margin-top: 10px;

      @include media("<1200px") {
        font-size: 12px;
        max-width: 350px;
      }
    }

    .btn {
      margin-top: 30px;
      min-width: 200px;
    }
  }

  .light-decor {
    @include media("<1200px") {
      display: none;
    }
    @keyframes light-fade {
      0%, 25%, 35%, 50% {
        opacity: 0;
      }
      20%, 30%, 55% {
        opacity: 1;
      }
    }

    .decor-item {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
      background: url("../../assets/images/light.png") no-repeat center / contain;
      animation: light-fade 3s infinite both alternate;
      opacity: 0;

      &:nth-child(1) {
        margin-left: -45vw;
      }

      &:nth-child(2) {
        margin-left: -30vw;
        margin-top: -3vw;
        animation-duration: 2s;
        animation-delay: .1s;
      }

      &:nth-child(3) {
        margin-left: -40vw;
        margin-top: 11vw;
        animation-delay: .2s;
        animation-duration: 1s;
      }

      &:nth-child(4) {
        margin-left: 44vw;
        margin-top: 6vw;
        animation-duration: 5s;
      }

      &:nth-child(5) {
        margin-left: 38vw;
        margin-top: -2vw;
        animation-delay: .3s;

      }

      &:nth-child(6) {
        margin-left: -7vw;
        margin-top: 1vw;
        animation-delay: .15s;
        animation-duration: 4s;

      }

      &:nth-child(7) {
        margin-left: -26vw;
        margin-top: 15vw;
        animation-delay: .5s;
        animation-duration: 2.5s;


      }

      &:nth-child(8) {
        margin-left: 33vw;
        margin-top: 12vw;
        animation-delay: .4s;
        animation-duration: 3.5s;


      }

      &:nth-child(9) {
        margin-left: 43vw;
        margin-top: 15vw;
        animation-delay: .45s;
        animation-duration: 4.7s;

      }

      &:nth-child(10) {
        margin-left: 38vw;
        margin-top: 8vw;
        animation-duration: 2.3s;
      }

      &:nth-child(11) {
        margin-left: -2vw;
        margin-top: 14vw;
        animation-delay: .25s;

      }

      &:nth-child(12) {
        margin-left: -29vw;
        margin-top: 8vw;
        animation-delay: .15s;
        animation-duration: 4.4s;


      }
    }
  }

  .slick-current {
    z-index: 5;
  }

  .custom-section {
    padding-top: 85px;

    @include media("<1200px") {
      padding-top: 50px;
    }

    &__title {
      font-size: 48px;
      line-height: 1;
      margin-bottom: 25px;
      font-family: $mainSansSerifBold;

      @include media("<768px") {
        font-size: 32px;
      }
    }

    &--small-indent {
      padding-top: 40px;
      @include media("<768px") {
        padding-top: 20px;
      }
    }
  }

  .commission {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    
    @include media("<1200px") {
      display: block;
    }
  }
  .commission-nav {
    width: 565px;
    flex: none;
    margin-right: 30px;
    padding: 0;
    list-style: none;
    max-width: 100%;
    li {
      margin-bottom: 20px;
      @include media("<1200px") {
        margin-bottom: 5px;
      }
    }
    button {
      display: flex;
      align-items: center;
      font-size: 24px;
      background: $darkBox;
      border-radius: 5px;
      padding: 10px 20px;
      width: 100%;
      border: none;
      font-family: $mainSansSerifBold;
      cursor: pointer;
      transition: all .2s ease;

      @include media("<1200px") {
        font-size: 16px;
        padding: 5px 15px;
      }
      &.active {
        background-color: $redColor;
      }

      img {
        width: 75px;
        height: 75px;
        object-fit: contain;
        flex: none;
        margin-right: 25px;
        @include media("<1200px") {
          width: 50px;
          height: 50px;
          margin-right: 15px;
        }
      }
    }
  }
  .commission-content {
    border-radius: 5px;
    background: $redColor;
    flex: 1;
    min-height: 440px;
    padding: 0 40px;
    padding-top: 30px;
    padding-bottom: 15px;
    overflow-x: hidden;

    @include media("<1200px") {
      padding: 15px;
    }
  }
  .commission-card {
    animation: show 1 .4s ease-out both;
    transform: scale(.97);

    @keyframes show {
      0%{
        transform: scale(.97);
        opacity: 0;
      }
      75% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    &__title {
      text-align: center;
      font-family: $mainSansSerifBold;
      font-size: 21px;
      margin-bottom: 10px;
    }
    &__items {
      font-size: 14px;
      list-style: none;
      padding: 0;

      li {
        +li {
          margin-top: 10px;
        }

        position: relative;

        &::before {
          content: "";
          width: 8px;
          height: 8px;
          background: $darkBox;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
  }

  .how-it-works-content {
    display: flex;
    padding-top: 50px;
    
    @include media("<1200px") {
      display: block;
    }

    &__images {
      width: 482px;
      height: 424px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media("<1200px") {
        width: 250px;
        height: 200px;
        margin: 0 auto;
        margin-bottom: 20px;
      }

      img {
        max-width: 100%;
        object-fit: contain;
        transition: all .3s ease;
        filter: blur(10px);
        opacity: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        &.active {
          filter: blur(0px);
          opacity: 1;
        }
      }
    }

  }
  .how-it-work-nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 565px;
    list-style: none;
    padding: 0;
    margin-left: auto;

    @include media("<1200px") {
      width: 100%;
    }

    >* {
      width: calc(50% - 15px);
      margin-bottom: 30px;
      @include media("<1200px") {
        width: calc(50% - 5px);
        margin-bottom: 10px;
      }
    }

    button {
      width: 100%;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 20px 25px;
      border-radius: 5px;
      background: $darkBox;
      height: 200px;
      cursor: pointer;
      border: none;
      font-size: 21px;
      line-height: 1.2;
      text-align: left;
      font-family: $mainSansSerifBold;
      transition: all .2s ease;

      @include media("<1200px") {
        font-size: 12px;
        height: 75px;
        padding: 10px;
      }

      span {
        position: absolute;
        right: 25px;
        bottom: 20px;
        margin-left: auto;
        font-size: 100px;
        line-height: .6;
        display: block;
        color: #051525;
        @include media("<1200px") {
          right: 10px;
          bottom: 10px;
          font-size: 32px;
        }
      }

      &.active {
        background-color: $redColor;

        span {
          color: #fff;
        }
      }
      
    }
  }

  .about-us-section {
    position: relative;
    padding: 70px 0;
    margin-top: 125px;
    overflow: hidden;
    background: $primaryColor;
    @include media('<=860px') {
      margin-top: 65px;
      padding: 50px 0;
    }

    .btn {
      min-width: 210px;
      @include media("<568px") {
        width: 100%;
      }
      background-color: $darkBox;

      &:hover {
        background-color: $redColor;
      }

    }

    &__title {

      @include media("<768px") {
        font-size: 28px;
      }

    }

    &__content {
      position: relative;
      z-index: 2;
    }

    &__img {
      width: 577px;
      position: absolute;
      right: 100px;
      top: 0;

      @include media('<old') {
        width: 450px;
        right: -50px;
      }
      @include media('<=860px') {
        display: none;
      }

      img {
        max-width: 100%;
      }
    }
  }

  .about-list {
    list-style: none;
    color: #fff;
    font-size: 18px;
    line-height: 1.5;
    padding: 0;
    margin: 0;
    margin-top: 35px;
    margin-bottom: 50px;

    @include media("<568px") {
      font-size: 16px;
    }

    li {
      + li {
        margin-top: 5px;
      }

      position: relative;
      padding-left: 25px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 10px;
        height: 10px;
        background-color: $darkBox;
        border-radius: 50%;
      }
    }
  }

  .menu-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $darkBox;
    z-index: 999999;
    overflow-y: auto;

    display: none;

    &.opened {
      display: block;
    }

    &__content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 100px;
    }

    .language-select {
      @include media("<1200px") {
        display: block;
        max-width: 200px;
        margin: 0 auto;
      }
    }
    .close-modal-button {
      position: absolute;
      left: 10px;
      top: 10px;
      border: none;
      background: $redColor;
      border-radius: 5px;
      width: 35px;
      height: 35px;
      font-size: 28px;
      font-family: $mainSansSerifBold;
      padding: 0;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

    }

    .pages-nav {
      &--header {
        display: flex;
        flex-direction: column;
        margin: 0;
        margin-bottom: 20px;

        li {
          text-align: center;

          + li {
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
    }

    .language-dropdown,
    .burger-menu,
    .header__buttons {
      display: flex;
    }

    .header__buttons {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 30px;
      margin: 0;

      > * {
        width: 50%;
      }

    }
  }

  .commission-page {
    .custom-section {
      &__title {
        text-align: center;
        margin-bottom: 40px;
      }
    }

    &__card {
      background: $darkBox;
      padding: 30px 20px;
      max-width: 700px;
      margin: 0 auto;
      border-radius: 5px;
    }

    &__list {
      list-style: none;
      padding: 0;

      li {
        + li {
          margin-top: 10px;
        }

        position: relative;
        padding-left: 20px;
        font-size: 16px;
        line-height: 1.5;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 6px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $primaryColor;
        }
      }
    }

    .btn {
      width: 210px;
      margin: 0 auto;
      margin-top: 20px;
      display: flex;
    }


  }

  .contact {

    &__text {
      text-align: center;
      max-width: 375px;
      margin: 0 auto;
      display: block;
      margin-bottom: 20px;
    }

  }
  .text-block {
    padding: 20px;
    border-radius: 5px;
    background: $darkBox;
    margin-bottom: 40px;
  }

  .contact-form {
    width: 565px;
    max-width: 100%;
    @include media("<1200px") {
      width: 100%;
    }

    &__text {
      font-size: 14px;
      line-height: 1.4;
      color: #fff;
      max-width: 420px;
      margin-bottom: 40px;
    }
    
    .ant-input {
      box-shadow: none;
      border: 1px solid transparent;
      background: #fff;
      border-radius: 6px;
      margin: 0;
      padding: 10px 15px;
      line-height: 25px;
      height: auto;
      min-height: initial;
      font-family: $mainSansSerifBold;
      color: $darkBox;

      &:focus {
        border-color: rgba(250, 250, 250, .35);
      }
    }

    .ant-form-item {
      margin-bottom: 15px;
    }

    .ant-form-item-has-error .ant-input:not(.ant-form-item-has-error .ant-input-disabled) {
      border-color: $redColor;
      background: #fff;
    }
    .recaptcha {
      display: flex;
      justify-content: center;
    }

    .ant-form-item-explain {
      font-size: 10px;
      line-height: 1;
      min-height: initial;
      height: auto;
      margin: 0;
      padding-left: 0;
      margin-top: 5px;
    }

    .btn {
      width: 100%;
    }
  }
  .contact-content {
    padding-bottom: 50px;
    display: flex;

    &__image {
      width: 500px;
      margin-top: -50px;
      margin-right: -50px;

      @include media("<1200px") {
        display: none;
      }

      img {
        max-width: 100%;
      }
    }
  }
  .faq-nav {
    display: flex;
    padding-bottom: 30px;

    @include media('<1200px') {
      flex-wrap: wrap;
    }

    > * {
      @include media('<1200px') {
        width: 100%;
      }

      + * {
        margin-left: 20px;
        @include media('<1200px') {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }

  .faq-nav-item {
    font-size: 24px;
    font-family: $mainSansSerifBold;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    padding: 15px 20px;
    line-height: 20px;
    background-color: $darkBox;
    transition: all .2s ease;

    &:hover {
      background-color: $primaryColor;
    }

    &.active {
      background-color: $redColor;
    }
  }

  .faq-item {
    background-color: $darkBox;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    margin-bottom: 20px;

    &__question {
      font-size: 18px;
      font-family: $mainSansSerifBold;
    }

    &__answer {
      font-size: 12px;
      font-style: italic;
      color: rgba(250, 250, 250, .75);
    }
  }

  .faq-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .conditions {
    .custom-section__title {
      text-align: center;
    }
  }

  .news-list {
    .ant-card {
      background: $darkBox;
      border: none;
      border-radius: 5px;
      padding: 20px;
      color: #fff;


      .news-list__post-img {
        max-width: 100%;
      }

      .ant-card-body {
        padding: 0;
        margin-top: 15px;
      }

      .ant-card-meta-title,
      .ant-card-meta-description,
      .news-list__post-date {
        color: #fff;
      }

      .ant-card-meta-title {
        font-family: $mainSansSerifBold;
      }

      .news-list__post-date {
        font-size: 12px;
        opacity: .5;
        font-style: italic;
        font-family: $mainSansSerif;

      }
    }
  }

  .testimonial-card {

    img {
      width: 150px;
      display: block;
      margin-top: 20px;
    }
    &.ant-card {
      background: $darkBox;
      border: none;
      border-radius: 5px;
      margin-bottom: 20px;

      .ant-card-head-title {
        color: #fff;
        padding: 0;
        height: auto;
        min-height: auto;
      }

      .ant-card-head {
        padding: 10px 20px;
        padding-bottom: 0;
        font-size: 18px;
        min-height: initial;
      }

      .ant-card-body {
        padding: 10px 20px;
        font-size: 12px;
        font-style: italic;
        color: rgba(250, 250, 250, .5);
      }

      .ant-divider {
        margin: 5px 0;
      }
    }
  }

  .pages-nav-drop {
    position: relative;

    &__link {
      position: relative;
      padding-right: 20px;

      @include media("<1200px") {
        padding-right: 0;
      }
    }

    .arrow-down {
      position: absolute;
      right: 0;
      top: 50%;
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
      transition: all .2s ease;

      @include media("<1200px") {
        display: none;
      }
    }

    &__drop-content {
      position: absolute;
      left: 0;
      top: 0;
      border-top: 40px solid transparent;
      white-space: nowrap;
      opacity: 0;
      filter: alpha(opacity=0);
      visibility: hidden;
      transition: all .2s ease;
      font-size: 12px;

      ul {
        padding: 10px;
        border-radius: 6px;
        background: $darkBox;
        list-style: none;

        li + li {
          margin-left: 0;
        }
      }

      @include media("<1200px") {
        position: relative;
        opacity: 1;
        filter: alpha(opacity=100);
        visibility: visible;
        border: none;

        ul {
          padding: 0;
        }
        li {
          + li {
            margin-top: 5px !important;
          }

          > * {
            font-size: 12px;
          }
        }

      }

    }

    &:hover {
      .pages-nav-drop {
        &__drop-content {
          display: block;
          opacity: 1;
          filter: alpha(opacity=100);
          visibility: visible;
          text-align: left;
        }
      }

      .arrow-down {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .footer {
    background-color: $darkBox;
    .ant-select-arrow {
      color: #fff !important;
    }
  }
  .footer-top {
    padding-top: 15px;
    display: flex;
    @include media("<1200px") {
      display: block;
    }
  }
  .language-select {
    width: 125px;
    font-family: $mainSansSerifBold;
    @include media("<1200px") {
      width: 100%;
    }
    .ant-select-selector {
      background: none;
      border: 1px solid rgba(250, 250, 250, 0.5);
    }
    .ant-select-selection-item {
      background: none !important;
      padding: 0 !important;
    }

    .ant-select-dropdown {
      background: $darkBox;
      border-radius: 5px;
      .ant-select-item-option {
        background: none !important;
        min-height: initial;
        padding: 5px 10px !important;
        color: #fff;
        &-active {
          color: $primaryColor;
        }
      }
    }
  }
  .footer-logo {
    align-self: center;
    width: 110px;
    display: inline-block;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .footer-col {
    margin-left: 60px;
    @include media("<1200px") {
      margin-left: 0;
      margin-top: 20px;
    }

    &__title {
      font-size: 20px;
      font-family: $mainSansSerifBold;
      color: #697B8B;

      @include media("<1200px") {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }

  .footer-nav {
    list-style: none;
    padding: 0;
    column-count: 2;
    line-height: 1;
    li {
      padding-right:  30px;
    }
    a {
      font-size: 14px;
      line-height: 30px;
      color: #697B8B;
      font-family: $mainSansSerif;
      display: flex;
      align-items: center;

      svg {
        width: 25px;
        height: 25px;
        flex: none;
        margin: 5px 0;
        margin-right: 10px;
        border-radius: 50%;
        overflow: hidden;
        path {
          fill: #697B8B;
        }
      }

      &.twitter-social-link {
        svg {
          border: 1px solid #697B8B;
          transition: all .2s ease;
        }
      }

      &:hover {
        color: $primaryColor;

        svg {
          border-color: $primaryColor;
          path {
            fill: $primaryColor;
          }
        }
      }
    }
  }
}

.error-page {
  color: #fff;
}

.error-page-content {
  &__title {
    color: #fff;
  }
}

.form-recaptcha {

  .recaptcha {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.dashboard-layout {
  position: relative;
  background: #051525;
  color: #fff;


}
.language-dropdown {
  img {
    width: 23px;
    box-shadow: 0 0 10px -2px gray;
  }
}
.dashboard-content {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  color: $darkBox;
  padding: 0 15px;

  &__wrap {
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
  }
}

.sidebar {
  flex: none;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 15px;
  transition: all .2s ease;
  margin-bottom: 15px;
  position: relative;

  &__top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 10px;
  }


  .language-dropdown {
    font-size: 0;
    width: 25px;
    flex: none;
    transition: all .2s ease;
    display: block !important;

    @include media("<1200px") {
      display: none !important;
    }

    .ant-menu {
      border-radius: 6px;
    }

    .ant-menu-item {
      line-height: 1;
      height: auto;
      margin: 0;
      padding: 5px;
    }
  }

  &.isCollapsed {
    width: 70px;

    .sidebar-logo,
    .logout-btn,
    .language-dropdown,
    .sidebar-nav-item__text {
      transform: scaleX(0);
      opacity: 0;
    }
  }
}

.sidebar-menu-button {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  flex: none;
  transition: all .2s ease;
  background: rgba($darkBox, .1);
  cursor: pointer;

  path {
    fill: $darkBox;
    transition: all .2s ease;

  }

  &:hover {
    background: rgba($darkBox, .2);
  }

  svg {
    max-width: 20px;
    max-height: 20px;
    transform: scaleX(-1);
    transition: all .2s ease;

  }

  &.active {
    background: $darkBox;

    svg {
      transform: scaleX(1);

    }

    path {
      fill: #fff;
    }
  }
}

.sidebar-logo {
  width: 110px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease;
  transform-origin: 0 0;
  margin-right: auto;

  > * {
    object-fit: contain;
    width: 100%;
    height: initial;
  }
}

.layout-header {
  width: 100%;
}

.header-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 15px;
  white-space: nowrap;
  overflow-x: auto;

  li + li {
    margin-left: 10px;
  }

  a {
    border-radius: 5px;
    padding: 5px 8px;
    background: #fff;
    font-size: 14px;
    font-family: $mainSansSerifBold;
    color: $darkBox;
    text-align: center;
    display: block;

    &:hover {
      color: $primaryColor;
    }

    &.active {
      color: #fff;
      background-color: $primaryColor;
    }
  }
}

.sidebar-navigation {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;

  @include media("<1200px") {
    display: none;
  }

  li {
    position: relative;

    &:hover {
      .sidebar-nav-drop {
        opacity: 1;
        visibility: visible;
        filter: alpha(opacity=100);
      }
    }
  }
}

.sidebar-nav-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: $mainSansSerifBold;
  color: $darkBox;
  border-radius: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  position: relative;

  &__icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 5px;
    flex: none;
    position: relative;

    path {
      fill: $darkBox;
      transition: all .2s ease;
    }

    > * {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__arrow {
    width: 8px;
    height: 8px;
    transition: all .2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

    > * {
      width: 100%;
      height: 100%;
    }

    path {
      fill: $darkBox;
      transition: all .2s ease;
    }
  }

  &__text {
    transition: transform .2s ease;
    transform-origin: 0 0;
    font-size: 12px;
    padding: 5px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    color: $primaryColor;

    .sidebar-nav-item {
      &__icon,
      &__arrow {
        path {
          fill: $primaryColor;
        }
      }
    }

  }

  &.active {
    &:not(&.hasChildren) {
      color: $primaryColor;
      background-color: rgba($primaryColor, .15);
    }

    @include media("<1200px") {
      color: $primaryColor;
      background-color: rgba($primaryColor, .15);
      .sidebar-nav-item {
        &__arrow {
          transform: rotate(180deg);
        }

        &__icon,
        &__arrow {
          path {
            fill: $primaryColor;
          }
        }
      }
      + .sidebar-nav-drop {
        display: block;
      }
    }


  }
}

.sidebar-nav-drop {
  list-style: none;
  margin: 0;
  font-size: 14px;
  position: absolute;
  right: 0;
  top: 100%;
  padding: 15px;
  border-radius: 5px;
  background: #fff;
  z-index: 999;
  border: 1px solid #f0f0f0;
  white-space: nowrap;
  min-width: 100%;
  opacity: 0;
  visibility: hidden;
  filter: alpha(opacity=0);
  transition: all .2s ease;

  @include media("<1200px") {
    opacity: 1;
    visibility: visible;
    filter: alpha(opacity=100);
    position: relative;
    padding: 0;
    border-radius: 0;
    border: none;
    padding-left: 15px;
    padding-bottom: 10px;
    font-size: 12px;
    display: none;
  }

  li {
    margin-top: 10px;
  }

  a {
    color: $darkBox;
    padding: 5px;
    border-radius: 5px;

    &:hover {
      color: $primaryColor;
    }

    &.active {
      background-color: rgba($primaryColor, .15);
      color: $primaryColor;

    }
  }
}

.sidebar-controls {
  display: flex;
  margin-left: 5px;
  @include media('<1200px') {
    display: none;
  }
}

.sidebar-burger-btn {
  display: none;

  @include media("<1200px") {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border: none;
    background: none;
    padding: 8px;
    margin-right: -8px;
    span {
      width: 20px;
      height: 2px;
      border-radius: 10px;
      background: $darkBox;
      transition: all .2s ease;

      + span {
        margin-top: 5px;
      }
    }

    &.active {
      span {
        &:nth-child(2) {
          transform: translate(0px, 2px) rotate(-45deg);
        }

        &:nth-child(1) {
          transform: translate(0px, 9px) rotate(45deg);
        }

        &:nth-child(3) {
          opacity: 0;
          transform: translateY(5px);
        }
      }
    }
  }
}

.mobile-inner-menu {
  position: absolute;
  left: 0;
  top: 100%;
  padding: 10px;
  background: #fff;
  width: 100%;
  border-top: 1px solid $greyMain;
  border-bottom: 1px solid $greyMain;
  z-index: 9999;

  &__top {
    display: flex;
    align-items: center;
  }

  .sidebar-controls {
    display: flex;
    margin-left: auto;
  }

  .sidebar-navigation {
    display: flex;
    flex-direction: column;
  }

  .language-dropdown {
    display: block !important;
  }

}

.sidebar-mails {
  .anticon {
    font-size: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    line-height: 1.25;
  }

  &__indicator {
    position: absolute;
    right: -3px;
    top: -3px;
    border: 1px solid #fff;
    background-color: $primaryColor;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-style: italic;
  }

  &__count {
    font-family: $mainSansSerif;
    font-size: 10px;
    color: rgba($darkBox, .5);
    font-style: italic;
  }

  &:hover {
    color: #000;
  }
}

.user-profile-preview {
  line-height: 1.25;
  display: flex;
  align-items: center;

  &__id {
    font-family: $mainSansSerif;
    font-size: 10px;
    color: rgba($darkBox, .5);
    font-style: italic;
    display: block;
  }

  .sidebar-nav-item {
    &__text {
      padding: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

.promo-steps {
  .step-icon {
    font-family: $mainSansSerifBold;
  }

  .ant-steps-item + .ant-steps-item {
    border-left: none;
    margin-left: 5px !important;
  }

  .ant-steps-item {
    border-radius: 5px;
    overflow: hidden;

    &-finish {
      background-color: rgba($primaryColor, .2);
      opacity: .5;
    }

    &-active {
      background-color: rgba($primaryColor, .2);

    }
  }
}

.logout-btn {
  border: none;
  background: none;
  width: 30px;
  height: 30px;
  padding: 5px;
  cursor: pointer;
  transition: all .2s ease;

  > * {
    width: 100%;
    height: 100%;
  }

  path {
    fill: $darkBox;
    transition: all .2s ease;
  }

  &:hover {
    path {
      fill: $primaryColor;
    }
  }
}

.system-table {
  overflow-x: auto;
  margin: 0 -24px;
  position: relative;
}

.ant-table-content {
  overflow-x: auto;
}

.inner-form-card {

  &__top {
    display: flex;
    padding-bottom: 20px;
  }

  &__controls {
    display: flex;
    padding-top: 25px;

    > * {
      + * {
        margin-left: 15px;
      }
    }
  }
}

.geo-chart-map {
  height: 700px;
  max-height: 60vh;


}

.geo-chart-map-wrap {
  margin-top: 20px;

  .ant-card-body {
    padding: 0;
  }
}

.geo-detail-modal {


  .ant-table-wrapper {
    overflow: auto;
    margin-top: 20px;
    white-space: nowrap;
  }
}

.dashboard-total-item {

  flex: none;
  margin-right: 5px;

  .cell-partner-income {
    width: 135px;
    height: 135px;
    border: 1px solid #f0f0f0;
  }
}

.cell-partner-income {
  background: #fff;
  padding: 15px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;

  &__description {
    font-size: 10px;
    line-height: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 5px;
    text-align: center;
  }
  .partner-income-details {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    list-style: none;
    padding-top: 5px;
    width: 100%;

    > * {
      width: 50%;
      text-align: center;
      font-size: 12px;

      + * {
        border-left: 1px solid rgba(0, 0, 0, 0.3);
      }
    }

    &__title {
      font-size: 10px;
      font-style: italic;
      color: rgba(0, 0, 0, 0.45);
    }

    &__value {
      font-weight: bold;
    }
  }

  &__total {
    text-align: center;

    + * {
      margin-top: 10px;
    }

    &.single {
      .cell-partner-income__total-value {
        font-weight: 900 ;
        font-size: 16px;
        font-family:"Baloo", sans-serif;
      }
    }
  }

  &__total-title {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  &__total-value {
    font-size: 16px;
    font-weight: bold;
  }

  p {
    margin: 0;
  }

  &--details {
    background: none;
    padding: 0;
    width: 175px;
    display: block;
    height: auto;


    .cell-partner-income {
      width: auto;

      &__total {
        text-align: left;
      }

      &__total-value {
        font-size: 12px;
        text-align: left;
      }
    }


    .partner-income-details {
      justify-content: flex-start;
      padding: 0;
      line-height: 13px;

      &__title {
        font-size: 9px;
      }

      &__value {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.65);

      }

      > * {
        + * {
          margin-left: 10px;
          padding-left: 10px;
        }

        width: auto;
        text-align: left;
      }
    }
  }

  &--sports {
    .cell-partner-income {
      &__total-value {
        font-size: 14px;
      }

      &__total-title {
        font-size: 12px;
      }
    }
  }


}

.ant-select-arrow {
  color: #051525 !important;
}

.gamers-dashboard {

  .gamers-chart {
    border: 1px solid #f0f0f0;
  }

  .filters-main {
    padding: 0;
  }

  .ant-collapse-header {
    padding-right: 65px !important;
    background: #fff;
  }

  .ant-collapse-arrow {
    right: 0 !important;
    top: 0 !important;
    transform: none !important;
  }
}

.dashboard-totals {
  display: flex;
  flex-wrap: wrap;

  &__grid {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 -2.5px;

    > * {
      flex: 0 0 calc(100% / 6);
      flex-grow: 1;
      padding: 0 2.5px;
      padding-bottom: 5px;
    }
  }

  &--gamers {
    .dashboard-totals-item {
      height: 135px;
    }
  }
}

.dashboard-totals-item {
  text-align: center;
  padding: 5px;
  background: #fff;
  border: 1px solid #f0f0f0;
  height: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.4;
  &__title {
    margin: 0;
    color: gray;
    font-size: 12px;
  }

  &__value {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }
}

.ant-tag {
  border-radius: 5px;
  background-color: rgba($darkBox, .05);
  border-color: rgba($darkBox, .1);
  color: $darkBox;

  &.ant-tag-green {
    background-color: rgba($primaryColor, .2);
    border-color: $primaryColor;
    color: $primaryColor;
  }
}

.ant-select-arrow {
  color: #fff;
}

.ant-pagination-item-active {
  border-color: $primaryColor !important;

  &:hover {
    border-color: $primaryColor !important;
  }

  a {
    color: $primaryColor !important;
  }
}

.ant-pagination-item {
  &:hover {

    a {
      color: $primaryColor;
    }
  }

}

.ant-pagination-item-link {
  .anticon {
    color: $primaryColor !important;

    path {
      fill: $primaryColor;
    }
  }
}

.ant-notification-notice {
  border-radius: 5px;
  font-family: $mainSansSerifBold;

  .anticon.ant-notification-notice-icon-success {
    color: $primaryColor;
  }

  .ant-notification-notice-close {
    color: #000;
  }
}

.ant-table-pagination {
  align-items: center;
}

.dashboard-pages {
  > * {
    + * {
      margin-top: 15px;
    }
  }
}

.empty-box {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  padding: 20px 10px;

  &__icon {
    width: 65px;
  }

  &__title {
    margin: 0;
    margin-left: 10px;
    font-size: 21px;
    font-family: $mainSansSerifBold;
    color: #091D32;
  }
}